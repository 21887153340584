import { render, staticRenderFns } from "./governoratesForm.vue?vue&type=template&id=96b41c16&scoped=true&"
import script from "./governoratesForm.vue?vue&type=script&lang=js&"
export * from "./governoratesForm.vue?vue&type=script&lang=js&"
import style0 from "./governoratesForm.vue?vue&type=style&index=0&id=96b41c16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96b41c16",
  null
  
)

export default component.exports