<template>
  <AppForm
    :id="id"
    ref="governateForm"
    class="p-1"
    :title="
      governate
        ? $t('edit_form', { name: governate.name }) || `Edit ${governate.name}`
        : $t('add_new_governate') || 'Add New Governate'
    "
    :errors-bag="errorsBag"
    :is-loading="isLoading"
    :success-message="successMessage"
    @hide="$emit('hide')"
    @submit.prevent="handleSubmit"
  >
    <AppInput
      v-model="governateData['name']"
      type="text"
      required
      :label="$t('governate') || 'Governerate'"
    />
    <AppInput
      v-model="governateData['name_ar']"
      type="text"
      required
      :label="$t('governate_in_arabic') || 'Country in Arabic'"
    />

    <AppSelect
      id="country-checkboxes"
      v-model="governateData['country']"
      :placeholder="$t('select_country') || 'Select Country'"
      :label="$t('country') || 'Country'"
      class="required"
      required
      search-store-action="countries/fetchAllData"
      :options="countries"
    />
  </AppForm>
</template>

<script>
import { AppInput, AppForm, AppSelect } from "@/components/form/index";

export default {
  components: {
    AppInput,
    AppForm,
    AppSelect,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    governate: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      governateData: {},
      successMessage: null,
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.governorates.isLoading.creating ||
        this.$store.state.governorates.isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state.governorates.errors;
    },
    countries() {
      return this.$store.state.countries.data;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  watch: {
    governate: {
      handler(val) {
        if (!val) {
          this.governateData = {};
          return;
        }
        this.governateData = {
          ...val,
          country: {
            label: this.isRTL ? val.country.name_ar : val.country.name,
            value: val.country.uuid,
          },
        };
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("countries/fetchAllData");
  },
  methods: {
    handleSubmit() {
      const actionName = this.governate ? "updateData" : "createData";

      this.$store
        .dispatch(`governorates/${actionName}`, {
          ...this.governateData,
          country: this.governateData.country?.value,
        })
        .then(() => {
          // this.$root.$emit("bv::hide::modal", this.id);
          this.$bvModal.hide(this.id);
          this.successMessage =
            this.$t("governate_has_been_added_successfully") ||
            "governate has been added Successfully";
          this.governateData = {};
        });
    },
    hideForm() {
      // this.$root.$emit("bv::hide::modal", this.id);
      this.$bvModal.hide(this.id);
      this.$refs.governateForm.reset();
      this.successMessage = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
