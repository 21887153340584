<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t("filters") || "Filters" }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <AppSelect
            id="countries-select"
            v-model="CountryFilter"
            :options="countries"
            class="w-100"
            :placeholder="$t('filter_by_country') || 'filter by Country'"
            :field-label="$t('country') || 'Country'"
            :label="$t('filter_by_country') || 'filter by Country'"
            search-store-action="countries/fetchAllData"
            @input="filterHandler"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import userCan from "@/mixins/UserCan";
import { AppSelect } from "@/components/form/index";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormCheckbox,
    AppSelect,
  },
  data() {
    return {
      governateFilter: null,
      CountryFilter: null,
      statusFilter: null,
      statusOptions: [
        {
          label: this.$t("all") || "All",
          value: null,
        },
        {
          label: this.$t("active") || "Active",
          value: 1,
        },
        {
          label: this.$t("inactive") || "Inactive",
          value: 0,
        },
      ],
    };
  },
  computed: {
    governorates() {
      return this.$store.state.governorates.data;
    },
    countries() {
      return this.$store.state.countries.data;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  created() {
    this.$store.dispatch("governorates/fetchAllData");
    this.$store.dispatch("countries/fetchAllData");
  },
  methods: {
    userCan,
    filterHandler() {
      // all filters
      const filters = {
        governate: this.governateFilter
          ? this.governateFilter.value
          : undefined,
        country: this.CountryFilter ? this.CountryFilter.value : undefined,
      };

      this.$emit("filter", filters);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
